import React, { useEffect } from "react"
import Layout from "../components/layout"
import PitPayLogo from "../images/pitpaywhitelogo.png"
import SEO from "../components/seo"
import Section1 from "../components/Section1"
import Section2 from "../components/Section2"
import Section3 from "../components/Section3"
import Section4 from "../components/Section4"
import Section5 from "../components/Section5"
import Section6 from "../components/Section6"
import Section7 from "../components/Section7"
import SmartBanner from "react-smartbanner"
import "../../node_modules/react-smartbanner/dist/main.css"

const IndexPage = () => {
  return (
    <Layout maxHeight={true}>
      <SmartBanner title={"Kart Pass"} />
      <SEO title="Kart Pass" />

      {/* <Section1 /> */}
      {/* <Section2 /> */}
      {/* <Section3 /> */}
      {/* <Section4 /> */}
      {/* <Section5 /> */}
      <Section6 />
      <Section7 />
    </Layout>
  )
}

export default IndexPage
